import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useGetLendioAppInitDataQuery } from '~/app/services/lendio';
import { Container } from '~/components/Container';
import GlobalLendio, { LendioInit, LendioOrganization } from './types/GlobalLendio';

const LendioLanding = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const qFormGuid = searchParams.get('guid');
  const lendio = (window as GlobalLendio & typeof globalThis).lendio;
  const { isFetching, isSuccess, data: lendioInitData } = useGetLendioAppInitDataQuery(
    qFormGuid ?? skipToken
  );

  useEffect(() => {
    if (!isFetching && isSuccess && lendioInitData) {
      const org = {
        id: lendioInitData.organization?.id,
        name: lendioInitData.organization?.name,
        street: lendioInitData.organization?.street ?? '',
        city: lendioInitData.organization?.city,
        state: lendioInitData.organization?.state,
        zipId: lendioInitData.organization?.zipId,
        phone: lendioInitData.organization?.phone,
        timeInBusiness: lendioInitData.organization?.timeInBusiness,
        industry: lendioInitData.organization?.industry ?? '',
        entityType: lendioInitData.organization?.entityType,
        financeAmount: lendioInitData.organization?.financeAmount,
        loanPurpose: lendioInitData.organization?.loanPurpose,
        ownerBirthDate: lendioInitData.organization?.ownerBirthDate ?? '',
      } as LendioOrganization;

      const testData = {
        jwt: lendioInitData.jwt,
        user: lendioInitData.user,
        organization: org,
      } as LendioInit;

      // eslint-disable-next-line no-console
      console.log(JSON.stringify(testData));

      // initialize app
      lendio.initialize(testData);

      // validate initialization before launching app
      lendio.validateInstallation();
    }
  });

  const clickHandler = () => {
    // launch app
    lendio.launchLoanApplication();
  };

  return (
    <>
      <Helmet>
        <title>Lendio - Business.LendingTree.com</title>
      </Helmet>
      <Container>
        <span>Hi Lendio</span>
        <button onClick={clickHandler}>Click me</button>
      </Container>
    </>
  );
};

export default LendioLanding;
