import { LendioInit } from '~/features/Lendio/types/GlobalLendio';
import { borrowerPortalApi } from '.';

export const lendioApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getLendioAppInitData: builder.query<LendioInit, string>({
      query: (guid) => 'lendio/init?guid=' + guid,
    }),
  }),
  overrideExisting: false,
});

export const { useGetLendioAppInitDataQuery } = lendioApi;
